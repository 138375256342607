.permissions-selector {

	margin-top: 30px;

	ul {
		margin-bottom: 20px;
	}

	> li > label {
		padding-left: 10px;
	} 

	label {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		padding-left: 20px;
		border-bottom: 1px solid $ui-light-grey;

		&:hover {
			background-color: $ui-lighter-grey;
		}
	}

	> li {
		
	}
}
