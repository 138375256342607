.main-footer {
	.title {
		span {
			color: white;
		}
	}
	a {
		color: $ui-light-grey-1;
		&:hover {
			color: white;
		}
	}
	background-color: #525f6b;
	padding-top: 0.5rem;

}

.footer__bottom {

	background-color: $ui-dark-blue-3;

	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
	text-align: center;
	color: $white;
	font-size: 1.3rem;

}

.footer-bottom__left {
	margin-bottom: 1rem;
	.no-desk {
		display: none;
	}
}

.footer-bottom__right {

}

.footer__content {
	background-color: $ui-dark-blue-1;
	color: white;
	padding: 3rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.logo-id-chronos {
		padding: 0 1.5rem;
		text-align: center;
		margin-bottom: 2.4rem;
	}
	.nav-footer {
		.nav-box {
			border-bottom: 1px solid $ui-dark-blue-0;
			padding-bottom: 1.2rem;
			padding-top: 1rem;
			max-height: 40px;
			overflow: hidden;
			transition: max-height .4s ease-in;
			.title-box {
				padding-left: 1.5rem;
				padding-right: 1.5rem;

				padding-bottom: 10px;
			}
		}
		.nav-box.open {
			max-height: 15rem;
		}
		.box-sub-nav {
			padding: 0 1.5rem;
			font-size: 1.2rem;
			color: $ui-light-grey-1;
			font-weight: 400;

			a {
				padding: 0.2rem 0;
			}

		}

	}
	.newsletter-box {
		max-width: 37.5rem;
		margin-top: 3rem;
		padding: 0 1.5rem;
		.title {
			font-size: 1.8rem;
			font-weight: bolder;
		}
		.error {
			margin-top: 10px;

			&.red {
				color: $alert-red;
			}

			&.green {
				color: $alert-green;
			}
		}
		.text {
			font-size: 1.2rem;
			font-weight: 400;
			line-height: 1.4rem;
			color: $ui-light-grey-1;
		}
		.form-box {
			margin-top: 1rem;
			.input-box {
				margin-right: 1.6rem;
				display: flex;
				flex-direction: column;
				font-size: 1.2rem;
				font-weight: bolder;
				color: $ui-light-grey-1;
				line-height: 2rem;
				input {
					border-width: 1px;
					border-color: $ui-light-blue-2;
					height: 4.3rem;
					background-color: transparent;
				}
			}
			.newsletter-subscribe-butt {
				img {
					height: 4.3rem;
					cursor: pointer;
				}
			}
		}
	}
}
@media (min-width: 767px) {
	.footer__bottom {
		flex-direction: row;
		justify-content: space-between;

	}

	.footer-bottom__left {
		margin-bottom: 0;
		br {
			display: none;
		}
		.no-desk {
			display: inline;
		}
	}
	.footer__content {
		flex-direction: row;
		justify-content: space-between;
		.logo-id-chronos {
			text-align: left;
		}
		.newsletter-box {
			margin-top: 0;
			.title {
				font-size: 2.4rem;
			}
			.form-box {
				input {
					width: 29rem;
				}
			}
		}

	}
}
@media (min-width: 992px) {
	.footer__content {
		.nav-footer {
			flex-direction: row;
			.nav-box {
				max-height: none;
				border-bottom: none;
				padding-right: 3rem;
			}
			.box-sub-nav {
				font-size: 1.4rem;
			}
			.expand {
				display: none;
			}
		}
	}
}
@media (min-width: 1199px) {
	.footer__content {
		padding: 9.3rem;
	}
}


@media print {
	.main-footer {
		display: none;
	}
}
