.alert {
	background-color: $ui-light-grey;
	padding: 2rem;
	margin: 2rem 0;
}

.sweet-alert.idc-alert {

	border-radius: 1px;

	button {
		@extend .btn-primary;

		&.cancel {
			@extend .btn-secondary;
		}
	}

	h2 {
		color: $black;
	}
}

.warning-message-box {
	border: 1px solid $ui-light-grey;
	color: $text-grey-3;
	padding: 3rem;

	h3 {
		margin: 0;
		margin-bottom: 2rem;
	}
}

.alert-container {
	margin:3rem 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.alert-box {
		width: 100%;
		min-height: 14.5rem;
		margin: 3rem 0;
		padding: 2rem 2rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		.icon-alert {
			margin-bottom: 1rem;
		}
		h4 {
			margin: 0;
			margin-bottom: 0.7rem;
		}
		&.confirm {
			border: 3px solid $alert-green;
			img {
				content: url("../images/icons/alert-conferma.svg");
			}
		}
		&.warning {
			border: 3px solid $alert-red;
			img {
				content: url("../images/icons/alert-warning.svg");
			}
		}
		&.info {
			border: 3px solid $alert-yellow;
			img {
				content: url("../images/icons/alert-info.svg");
			}
		}
	}
}
.print-action-container {
	margin-top: 50px;
	.print-box {
		padding-left: 20px;
		padding-right: 20px;
		.minor {
			margin-right: 18px;
			img {
				width: 12px;
				height: 14px;
			}
		}
		.name {
			font-weight: bold;
		}
	}
	.alert-container {
		margin-top: 0;
	}
	@media (min-width: 1200px) {
		.print-box {
			padding-left: 40px;
			padding-right: 40px;
		}
		.print-type {
			margin-top: -80px;
		}
	}
}


/*
 * Mini Alert
 */
%mini-alert {

	background-color: $ui-lighter-grey;
	padding: 20px;
	padding-left: 70px;
	color: $text-grey-2;
	text-align: left;
	max-width: 500px;
	margin:30px auto;

	line-height: 20px;

	background-position: center left 15px;
	background-size: 35px auto;
	background-repeat: no-repeat;


	border:1px solid $ui-light-grey;

	h4 {
		margin: 0;
	}

	display:block;

	@media (max-width: 767px) {
		padding-left: 20px;
		background-image: none!important;
	}

}

.mini-alert--info {
	@extend %mini-alert;
	background-image: url("../images/icons/alert-info.svg");
}

.mini-alert--warning {
	@extend %mini-alert;
	background-image: url("../images/icons/alert-warning.svg");
}

.mini-alert--success {
	@extend %mini-alert;
	background-image: url("../images/icons/alert-conferma.svg");
}


.mini-alert--bank-infos {

	@extend %mini-alert;
	background-image: url("../images/icons/bank.svg");

	li {
		margin: 5px 0;
	}

	label {
		color: black;
	}

}
