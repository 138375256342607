.scrollable-overlay {
	display: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	background-color: $terms-conditions-bg;
	z-index: 111111;
	padding: 2rem;
	.scrollable-overlay-box {
		background-color: white;
		max-width: 1256px;
		width: 100%;
		height: 80vh;
		.box-header {
			height: 200px;
			.box-title {
				padding: 0 2rem;
				max-width: 384px;
			}
			h4 {
				line-height: 1.2;
				margin: 0;
				margin-top: 1rem;
				font-size: 1.7rem;
			}
		}
		.container-text {
			height: calc(100% - 340px);
			background: $ui-lighter-grey;
			padding: 1.7rem;
			display: flex;
			.box-text {
				max-height: 100%;
				overflow-y: scroll;
				height: 100%;
				background-color: white;
				width: 100%;
			}
			.text-wrap {
				padding: 2rem 2rem;
				max-width: $max-width-container;
				h4 {
					margin-top: 1.5rem;
					font-size: 1.5rem;
					line-height: 1.2;
				}
			}
		}
		.box-buttons {
			height: 140px;
			display: flex;
			justify-content: center;
			align-items: center;
			.flex-start {
				@media (max-width: 600px) {
					flex-direction: column;
				}
			}
			button {
				&:first-child {
					margin-right: 1.5rem;
					@media (max-width: 600px) {
						margin-right: 0;
						margin-bottom: 5px
					}
				}
			}
		}
	}
}

@media (min-width: 767px) {
	.scrollable-overlay {
		.scrollable-overlay-box {
			.box-header {
				h4 {
					font-size: 2.4rem;
					margin-top: 2rem;
				}
			}
			.container-text {
				.text-wrap {
					padding: 7rem 2rem;
					h4 {
						font-size: 1.8rem;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}
