.race-manager-nav {

	background-color: $ui-light-grey;

	.tabs-wrapper__tabbed-nav {

		margin: 0 auto;

	}

}

.race-manager-race-list {

	.box {

		h4, h5 {
			margin-top: 0;
			text-align: left;
		}

		h4 {
			margin-bottom: 0px;
		}

	}

	.race-management-actions {
		text-align: right;
	}

}
