.profile-info {
	width: 100%;
	box-shadow: $box-shadow;
	padding-bottom: 30px;
	.box-img-profile {
		height: 190px;
		width: 100%;
		background-color: $ui-light-blue-1;
		align-items: center;
		padding-top: 30px;
	}
	.initials-box {
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-top: -35px;
		font-family: "Poppins", sans-serif;
		font-weight: 400;
		font-size: 28px;
		color: white
	}
	.initials {
		width: 70px;
		height: 70px;
		background-color: $ui-yellow;
		border-radius: 50%;
	}
	.name-box {
		h4 {
			margin-top: 25px;
			margin-bottom: 47px;
		}
	}
	.box-info-profile, .setting-profile {
		padding: 0 1.5rem;
		.text {
			padding-left: 10px;
		}
	}
	.info-profile-line {
		padding-bottom: 18px;
		&:last-child {
			padding-bottom: 40px;
		}
	}
	.setting-profile {
		.text {
			font-weight: bolder;
			color: $ui-light-blue-2;
			font-family: "Poppins", sans-serif;
		}
	}

}
.container-profile-info {
	.alert-container {
		padding-left: 0;
		padding-right: 0;
	}
}
.box-stats {
	width: 100%;
	align-items: center;
	background-color: white;
	&.empty {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		background-color: $ui-lighter-grey;
		h2 {
			margin: 0;
			color: $text-grey-2;
		}
		height: 150px;
	}
	.line-stat {
		width: 100%;
	}
	.box-stat {
		width: 100%;
		height: 120px;
		text-align: center;
		color: white;
		float: left;

		.title-stat {
			font-size: 16px;
			font-weight: bolder;
		}
		.result-stat {
			font-family: "Poppins", sans-serif;
			font-weight: 600;
			font-size: 38px;

		}
		&.light {
			background-color: $link-azure-1;
		}
		&.medium {
			background-color: $link-azure-2;
		}
		&.dark {
			background-color: $link-azure-3;
		}
	}
}
@media (min-width: 767px) {
	.profile-info {
		.box-img-profile {
			height: 293px;
		}
		.initials {
			width: 107px;
			height: 107px;
			background-color: $ui-yellow;
			border-radius: 50%;
		}
		.initials-box {

			font-size: 36px;
			color: white
		}
	}
}
@media (min-width: 992px) {
	.profile-info {
		max-width: 455px;
	}
	.box-stats {
		max-height: 419px;
		height: 100%;
		justify-content: center;
	}
	.container-profile-info {
		flex-direction: row;
		justify-content: flex-start;
		.alert-container {
			padding-bottom: 147px;
			.alert-box {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.alert-results-container {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		padding-left: 30px;
		width: 100%;
		.alert-container {
			width: 100%;
			align-items: flex-start;
			margin-left: 0;

		}
	}
}
@media (min-width: 1199px) {
	.box-stats {
		display: flex;
		flex-direction: column;
		.line-stat {
			flex-direction: row;
			.box-stat {
				margin-right: 25px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
