.race-ultra-slim-container {
  background-color: white;
  height: 46px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(176, 176, 176, 0.5);
  padding-left: 17px;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  .race-info-ultra-slim {
    width: 100%;
    align-items: center;
    .name {
      font-weight: bolder;
      width: 100%;
      color: $ui-dark-blue-2;
      padding-right: 10px;
      text-align: left;
    }
    .date {
      align-items: center;
      border-left: 1px solid $ui-light-grey-1;
      min-width: 76px;
      justify-content: center;

      .day {
        font-weight: bolder;
        font-size: 2rem;
        color: $ui-dark-blue-0;
        margin-right: 6.5px;
      }
      .month-race,
      .year {
        font-size: 10px;
        font-weight: 700;
      }

      .month-race {
        color: $alert-azure;
      }
      .year {
        color: $text-grey-2;
      }
    }
  }
  @include cq-prolyfill("width < 360") {
    .race-info-ultra-slim {
      .name {
        font-size: 12px;
      }
    }
  }
}
