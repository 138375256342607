.single-news-container {
	.image-news {
		max-width: 100%;
	}
	ul {
		list-style: disc;
		padding-inline-start: 16px;
	}

	h4 {
		margin-bottom: 0.4rem;
		@media (max-width: 767px) {
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}

	h1 {
		margin-top: 0;
		@media (max-width: 767px) {
			font-size: 3.5rem;
			line-height: 4rem;
			margin-top: 1rem;
			margin-bottom: 2.5rem;
		}
	}
}

.ql-editor ul {
	padding-left: 0 !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
	padding-left: 10px !important;
}

.image-input-box {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
