.event-races-list {

	max-width: 1400px;
	margin: 0 auto;

}

.race-block-dash-home {


	.event-year {

		position: absolute;
		right: 0;
		margin-top: 30px;
		margin-right:45px;
		padding-left:10px;
		border-left: 1px solid #d3d3d3;

		h2 {
			font-size: 3.5rem;
			margin: 0;
		}

		@include cq-prolyfill("width <= 700px") {
			margin-top:15px;
			font-size: 3rem;
		}

	}

}
