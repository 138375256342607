#homepage {

	.home-header-cta {

		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.6rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		margin:0 5px;

		.icon {

			display: inline-block;
			height: 50px;
			width: 50px;
			min-width: 50px;
			background-position: center left;
			background-size: auto 80%;
			margin-right: 10px;

			&.results-cta {
				background-image: url('../images/homepage/risultati.svg');
			}

			&.organize_event-cta {
				background-image: url('../images/homepage/organizza.svg');
			}

			&.sign_up-cta {
				background-image: url('../images/homepage/iscriviti.svg');
			}

		}


	}


  h3 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    @media (min-width: 768px) {
      font-size: 3rem;
      line-height: 3.8rem;
    }
  }
  #first-home {
    background-position: center;
    background-size: cover;
  }
  #devices,
  #integrations,
  #swiper-section,
  #news,
  #first-home {

    display: flex;
    align-items: flex-start;
    .container {
      width: 100%;
      padding: 0 2rem;
      h3 {
        @media (min-width: 768px) {
          margin-bottom: 30px;
        }
      }
      max-width: 893px;
      margin-left: auto;
      margin-right: auto;
      .text {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 25px;
        @media (min-width: 768px) {
          margin-bottom: 56px;
        }
      }
      .box-img {
        margin-top: 50px;
        @media (min-width: 768px) {
          margin-top: 77px;
        }
      }
    }
  }
  #first-home {
    background-image: url('../images/homepage/homepage-header.jpg');
		background-position: center bottom;
    background-size: cover;
		text-align: center;

		@media (min-width: 1700px) {
			background-position: center -190px;
		}


    .container {
      @media (min-width: 1400px) {
        max-width: 1200px;
      }
    }
    .title-box {
      h2 {
        margin-top: 0px;
        margin-bottom: 0;
        color: $ui-dark-blue-1;
      }
      h3 {
        margin-top: 0;
      }
      @media (min-width: 767px) {
        margin-bottom: 190px;
        h2 {
          font-size: 55px;
        }
        h3 {
          font-size: 36px;
        }
      }
    }
    .clock,
    .runner {
      width: 100%;
      @media (min-width: 767px) {
        width: 50%;
        display: flex;
        align-items: center;
      }
      @media (min-width: 991px) {
        width: 30%;
      }
    }
    .runner {
      display: none;
      @media (min-width: 991px) {
        display: flex;
      }
    }
    .clock {
      @media (min-width: 991px) {
        display: flex;
        justify-content: center;
      }
      img {
        max-width: 70px;
        @media (min-width: 340px) {
          max-width: 100px;
        }
        @media (min-width: 400px) {
          margin-bottom: 20px;
          max-width: 130px;
        }
        @media (min-width: 767px) {
          max-width: 180px;
        }
        @media (min-width: 991px) {
          max-width: none;
        }
      }
    }
    .container-home-element {
      @media (min-width: 767px) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        flex-direction: row;
      }
      @media (min-width: 767px) {
        justify-content: space-around;
        max-width: 100%;
      }
    }
    .title-upcoming-event {
      text-align: left;
      @media (min-width: 767px) {
        text-align: right;
      }
      h4 {
        margin-top: 15px;
        margin-bottom: 5px;
      }
    }
    .box-upcoming-race {
      margin-bottom: 5px;
      @media (min-width: 340px) {
        margin-bottom: 15px;
      }
    }
  }
  #integrations {
    align-items: flex-end;
  }

	#first-home {
    background-color: $ui-lighter-grey;
	}

  #swiper-section {
    flex-direction: column;

    background-color: $ui-lighter-grey;
    .box-swiper-animation {
      display: flex;
      max-width: 1150px;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
      .svg-container,
      .swiper-container {
        float: left;
        width: 100%;
        @media (min-width: 768px) {
          width: 50%;
        }
      }
      .svg-container {
        .artwork-class {
          transition: all 0.32s linear;
          z-index: 10;
          &.animated {
            transform-origin: 66% 30%;
          }
        }
      }
    }
  }

  .wrapper-first-section {
    align-items: center;
		padding: 50px 0;
  }

  .img-box-home {
    max-width: 280px;
    margin-bottom: 30px;

    @media (min-width: 400px) {
      max-width: none;
    }
    @media (min-width: 768px) {
      max-width: 400px;
    }
    @media (min-width: 991px) {
      max-width: 500px;
      margin-bottom: 38px;
    }
  }

  .box-button-home {

    a {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      button {

        width: 260px;
      }
    }

    @media (min-width: 991px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      a {
        margin-bottom: 0px;
      }
    }
  }
}
