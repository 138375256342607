.sld-select {

	display: inline-block;
	text-align: left;

	$padding-right: 20px; // space for arrow icon

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding: 10px;
		border: 1px solid $text-grey-1;
		background-color: $white;
		color: $text-grey-2;
		padding-right: $padding-right;
	}

	.sld-select__options-wrapper li {

		border-top: 0;

		&:hover {
			color: $white;
			background-color: $ui-light-blue-2;
		}

	}

	.sld-select__selected-option {

		background-image: url("../images/icons/icon-arrow-down.svg");
		background-position: right 10px center;
		background-repeat: no-repeat;
		padding-right: $padding-right;

	}

}

.sld-select--no-border {

	.sld-select__selected-option {

		border: 0;
		padding: 0.5rem;
		background-color: transparent;
		background-position: right center;

		font-weight: 700;
		text-align: right;
		color: $ui-light-blue-2;

		padding-right: 20px;

		&:hover {
			color: $ui-dark-blue-1;
		}

	}

	.sld-select__options-wrapper::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: #F5F5F5;
	}

	.sld-select__options-wrapper::-webkit-scrollbar {
		width: 5px;
		background-color: #F5F5F5;
	}

	.sld-select__options-wrapper::-webkit-scrollbar-thumb {
		background-color: #999;
		border: 2px solid #999;
	}

	.sld-select__options-wrapper li {

		color: $ui-light-grey;
		background-color: $ui-dark-blue-1;

		font-weight: 600;

		border: 0;
		border-bottom: 1px solid $ui-light-blue-2;

		&:first-child {
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}

		&:last-child {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}

		&:hover {
			background-color: $ui-light-blue-2;
		}

		&:active,
		&.selected,
		 {
			background-color: $ui-yellow;
		}

	}

}
