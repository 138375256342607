.standard-table {
	width: 100%;

	thead {
		background-color: $ui-light-blue-1;

		color: $ui-dark-blue-2;
		font-weight: bold;
	}

	tbody {
		color: $text-grey-3;
		font-size: 1.4rem;
	}

	.pallino {
		width: 12px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	tr.even,
	tr:nth-child(even) {
		background-color: $ui-light-grey;
	}

	tr.clickable {
		cursor: pointer;
	}

	tbody tr:hover,
	tbody tr:active {
		//color: $white;
	}

	tbody tr:hover {
		background-color: $ui-light-blue-1;
	}

	tbody tr:active {
		//background-color: $ui-dark-blue-1;
	}

	th,
	td {
		white-space: normal;
		text-align: center;

		&.no-wrap {
			white-space: nowrap;
		}
	}

	th {
		padding: 1rem;
		white-space: nowrap;
		text-transform: capitalize;
		font-size: 1.5rem;
		font-weight: 800;
		outline: none;

		vertical-align: middle;

		&.sorting {
			cursor: pointer;
		}
	}

	td {
		padding: 1rem;
		font-size: 1.2rem;
	}

	.group-tax-color {
		padding: 0 0 0 0;
		position: relative;
	}

	th.sorting_desc .order-icon,
	th.sorting_asc .order-icon,
	th.sorting .order-icon {
		display: inline-block;

		height: 1.5rem;
		width: 1.5rem;

		margin-left: 0.6rem;

		background-image: url("../images/icons/icon-arrow-up-down.svg");
		background-size: auto 1.5rem;
	}

	th.sorting_asc {
		.order-icon {
			height: 0.7rem;
			margin-bottom: 0.3rem;
			background-position: center top;
		}
	}

	th.sorting_desc {
		.order-icon {
			height: 0.7rem;
			margin-bottom: 0.3rem;
			background-position: center bottom;
		}
	}

	&.standard-table--admin {
		&.margin {
			margin: 30px 0;
		}

		&.hidden {
			display: none;
		}

		tbody tr:hover {
			color: $text-grey-3;
			background-color: $ui-light-blue-1;
		}
	}
}

// from datatables css
table.fixedHeader-floating {
	position: fixed !important;
	background-color: white;
}

.empty-table-alert-row {
	background-color: $ui-lighter-grey;
}

.btn-pointer {
	cursor: pointer;
}

tfoot {
	border-top: 1px solid $text-grey-1;
	padding-top: 10px;
}
