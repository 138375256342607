.login-box {

	max-width: 40rem;
	margin: 0 auto;
	margin-top: 5rem;
	margin-bottom: 5rem;

	background-color: $white;
	color: $text-grey-2;

	padding-top: 4rem;

	box-shadow: $box-shadow;

	form {
		padding: 4rem;
	}

	h3 {
		margin: 0;
		padding: 0 4rem;
	}

	footer {

		background-color: $ui-light-blue-1;
		padding: 4rem;

		h4 {
			margin-top: 0;
			color: $ui-dark-blue-2;
		}
	}

}
