.subscriptions-box-closed {
	.box-content {
		display: none;
	}

	.box__box-title {
		margin: 0;
	}
}

.race-subscribe {
	.login-box {
		box-shadow: none;
		margin-top: 0;
		padding-top: 0;
		margin-bottom: 7rem;

		form {
			padding-bottom: 0;
		}

		.form-footer {
			margin-bottom: 0;
		}
	}

	.box-content {
		margin: 7rem 0;
	}

	.step-num {
		font-size: 2.5rem;
		line-height: 2.5rem;
		color: $ui-light-blue-2;
		padding-left: 1rem;
		padding-right: 2rem;
		margin-right: 2rem;
		border-right: 1px solid #ccc;
		font-family: "Poppins", sans-serif;
	}

	.box.completed {
		.box__box-title {
			cursor: pointer;
			background-image: url("../images/icons/icon-done.svg");
			background-position: right 2rem center;
		}
	}
}

.birth_date_fields {
	.select {
		margin-right: 5px;
		display: inline-block;

		&:nth-child(1),
		&:nth-child(2) {
			width: 30%;
		}

		&:nth-child(3) {
			width: 30%;
		}
	}
}

// .team-form-wrapper,
.italian-team,
.non-italian-team {
	display: none;

	&.visible {
		display: block;
	}
}

.autocomplete-suggestions {
	border: 1px solid #999;
	background: #fff;
	overflow: auto;
}
.autocomplete-suggestion {
	padding: 2px 5px;
	white-space: nowrap;
	overflow: hidden;
}
.autocomplete-selected {
	background: #f0f0f0;
}
.autocomplete-suggestions strong {
	font-weight: normal;
	color: #3399ff;
}
.autocomplete-group {
	padding: 2px 5px;
}
.autocomplete-group strong {
	display: block;
	border-bottom: 1px solid #000;
}

.registration-options-wrapper {
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: 50px;
}

.registration-options {
	display: grid;
	grid-template-columns: 48% 48%;
	grid-column-gap: 4%;
	grid-row-gap: 20px;
	text-align: center;
}

/*
 * Team members
 */

.team-members {
	width: 100%;
	font-size: 1.3rem;

	td {
		line-height: 35px;
		padding: 0 10px;
		cursor: pointer;
	}

	tr:hover {
		background-color: $ui-light-grey;
	}

	tr.selected {
		background-color: rgba($alert-green, 0.5);
		button {
			display: none;
		}
	}
}

.team-members-wrapper {
	max-height: 200px;
	overflow-y: scroll;
	border: 1px solid $ui-light-grey;
	border-top: 0;
}

/*
 * Stripe
 */

.stripe-form {
	//
	// label .field.is-focused + span span,
	// label .field:not(.is-empty) + span span {
	// 	transform: scale(0.68) translateY(-36px);
	// 	cursor: default;
	// }
	//
	// label .field.is-focused + span {
	// 	border-bottom-color: #34D08C;
	// }
	//
	// .field {
	// 	background: transparent;
	// 	font-weight: 300;
	// 	border: 0;
	// 	color: white;
	// 	outline: none;
	// 	cursor: text;
	// 	display: block;
	// 	width: 100%;
	// 	line-height: 32px;
	// 	padding-bottom: 3px;
	// 	transition: opacity 200ms ease-in-out;
	// }
	//
	// .field::-webkit-input-placeholder { color: #8898AA; }
	// .field::-moz-placeholder { color: #8898AA; }
	//
	// /* IE doesn't show placeholders when empty+focused */
	// .field:-ms-input-placeholder { color: #424770; }
	//
	// .field.is-empty:not(.is-focused) {
	// 	opacity: 0;
	// }
	//

	.card-field-wrapper {
		padding: 1.5rem 1.5rem;
		outline: none;
		border: 1px solid #dfdfdf;
		box-sizing: border-box;
		color: #939393;
	}

	button {
		float: left;
		display: block;
		background: #34d08c;
		color: white;
		border-radius: 2px;
		border: 0;
		margin-top: 20px;
		font-size: 19px;
		font-weight: 400;
		width: 100%;
		height: 47px;
		line-height: 45px;
		outline: none;
	}

	button:focus {
		background: #24b47e;
	}

	button:active {
		background: #159570;
	}

	.outcome {
		float: left;
		width: 100%;
		padding-top: 8px;
		min-height: 20px;
		text-align: center;
	}

	.success,
	.error {
		display: none;
		font-size: 15px;
	}

	.success.visible,
	.error.visible {
		display: inline;
	}

	.error {
		color: #e4584c;
	}

	.success {
		color: #34d08c;
	}

	.success .token {
		font-weight: 500;
		font-size: 15px;
	}
}

/*
 * Payment methods
 */
.payment-methods-wrapper {
	margin: 3rem 0;
}

.payment-method-box {
	display: none;
}

#paypal-button-container {
	max-width: 300px;
	margin: 0 auto;
}

/*
 * Step 1
 */
#racer_card_number.card-found {
	border: 1px solid $alert-green;
}

#racer_card_number.card-not-found {
	border: 1px solid $alert-red;
}

.mini-alert--info.alert-card-not-found {
	display: none;
	opacity: 0;
	transition: opacity 0.3s;

	&.visible {
		display: block;
		opacity: 1;
	}
}
