.main-nav {
	height: 7rem;
	background-color: $ui-dark-blue-2;
	display: flex;
	justify-content: space-between;
	position: absolute;
	width: 100%;

	.logo {
		height: 7rem;
		width: 7rem;
		background-color: $ui-dark-blue-3;
		background-image: url($logo);
		background-repeat: no-repeat;
		background-size: 60% auto;
		background-position: center center;
	}

	&.sticky {
		position: fixed;
		width: 100%;
		top: 0;
	}
}

.main-menu {
	display: flex;
	justify-content: center;
	flex-grow: 1;
	max-width: 800px;

	li {
		list-style: none;
		align-self: center;
		flex-grow: 1;

		color: $white;
		text-transform: uppercase;
		font-size: 1.8rem;
		font-weight: 700;
		letter-spacing: 1px;

		padding: 0 2%;
	}

	a,
	a:hover {
		color: $white;
	}
}

.facilities {
	display: flex;

	.login,
	.language-switch,
	.main-menu-collapse {
		background-color: $ui-dark-blue-1;
		&.open-box {
			background-color: $text-blue-2;
		}
	}

	.login-icon,
	.language-icon,
	.logged-in-icon {
		height: 7rem;
		width: 10rem;

		background-position: center center;
		background-repeat: no-repeat;
		background-size: 35% auto;

		cursor: pointer;
	}

	.logged-in-icon {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logged-in-icon .initials {
		width: 3.5rem;
		height: 3.5rem;

		text-align: center;

		line-height: 3.5rem;
		color: $white;
		font-weight: 800;

		margin: 0 auto;

		border-radius: 50%;

		background-color: $ui-yellow;
	}

	.language-icon {
		box-sizing: border-box;
		padding-left: 6rem;

		background-image: url("../images/icons/icon-language.svg");
		background-position: 10px center;

		color: $white;
		text-transform: uppercase;
		font-weight: 800;
		line-height: 7rem;
	}

	.sub-menu {
		position: absolute;
		right: 0;
		color: $ui-dark-grey;

		background-color: $ui-dark-blue-1;
	}

	.login-icon {
		font-size: 0;

		background-image: url("../images/icons/icon-login.svg");

		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.sub-menu {
		display: none;
		width: 20rem;

		a {
			padding: 2rem;
			font-size: 1.4rem;
			font-weight: 700;
			display: block;
			color: $ui-dark-grey;
		}

		a:hover {
			color: $white;
			background-color: $text-blue-2;
		}

		.selected,
		a:active,
		a:hover.selected {
			background-color: $text-blue-2;
			color: $white;
		}

		.selected {
			background-image: url("../images/icons/icon-check.svg");
			background-position: 20px center;
		}
	}

	.language-switch {
		.sub-menu {
			text-align: right;
		}

		&.open-box {
			.sub-menu {
				display: block;
			}
		}
	}

	.hamb-icon {
		font-size: 0;

		background-image: url("../images/icons/burger-button-white.svg");

		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.main-menu-collapse {
		display: block;
		&.open-box {
			.hamb-icon {
				background-image: url("../images/icons/close-white.svg");
			}
			.sub-menu {
				display: block;
			}
		}
	}
	.login {
		&.open-box {
			.sub-menu {
				display: block;
			}
		}
	}
}

@media (max-width: 1024px) {
}

@media (max-width: 700px) {
	.main-menu-collapse {
		display: none;
	}
	.main-menu {
		display: none;
	}
}
@media (max-width: 700px) {
	.main-menu-collapse {
		display: none;
	}
	.main-menu {
		display: none;
	}
	.facilities {
		.login-icon,
		.language-icon,
		.logged-in-icon {
			height: 7rem;
			width: 9rem;
		}
		.hamb-icon {
			height: 7rem;
			width: 7rem;
		}
	}
}

@media print {
	.main-nav {
		display: none;
	}
}
