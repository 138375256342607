.tabs-wrapper {
	overflow: hidden;
}

.tabs-wrapper__tabbed-nav {
	white-space: nowrap;
	display: table;
}

[aria-hidden="true"] {
	opacity: 0;
	position: absolute;
	z-index: -9999;
	pointer-events: none;
}

.tab {
	display: inline-block;

	font-weight: 700;
	font-size: 1.8rem;
	letter-spacing: 0.5px;
	padding: 0 1rem;
	color: $ui-light-blue-2;

	border-bottom: 5px solid transparent;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: 65px;

	&:hover {
		color: $ui-light-blue-3;
	}

	&:active,
	&.selected {
		color: $ui-dark-blue-1;
	}

	&:last-child {
		margin-right: 0;
	}
	span {
		margin-left: 6px;
		font-size: 1rem;
		font-weight: 500;
		color: $text-grey-2;
	}
	label {
		display: inline-block;
		cursor: pointer;
	}

	&.selected {
		border-bottom: 5px solid $ui-yellow;
	}
}

.tabs-separator {
	display: table-cell;
	position: relative;
	padding: 0 1rem;

	.tabs-separator__border {
		position: absolute;
		box-sizing: border-box;

		height: 60%;
		top: 20%;
		border-right: 1px solid #ccc;

		border-bottom: 5px solid transparent;
	}

	&:last-child {
		display: none;
	}
}

@media (max-width: 800px) {
	.tab {
		flex-direction: column;
		height: 40px;
		span {
			margin-left: 0;
		}
	}
	.route-tab {
		font-size: 1.4rem;
	}
}
