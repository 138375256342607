.search-box {

	max-width: 800px;
	margin: 30px auto;

	input.search-box-input {
		max-width: 100%;
		width: 100%;
		font-size: 25px;

		&::placeholder {
			color: #d4d4d4;
		}
	}

	button {
		cursor: pointer;
		outline: none;
		margin-left:10px;
	}

}
