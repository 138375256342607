// select {
// 	height: 45px;
// 	padding: 1.5rem;
// 	outline: none;
// 	border: 1px solid $text-grey-1;
//
// 	box-sizing: border-box;
//
// 	color: $text-grey-2;
//
// 	background-color: $white;
//
// 	&:focus {
// 		background-color: $ui-light-grey;
// 		border: 1px solid $ui-light-blue-3;
// 	}
//
// 	&.search {
//
// 		padding-left: 45px;
//
// 		background-image: url("../images/icons/icon-lens.svg");
// 		background-position: 10px center;
// 		background-repeat: no-repeat;
//
// 		font-size: 1.8rem;
//
// 	}
//
// }


// Select styling

.select {
  position: relative;
  height: 47.5px;
  background: white;
	color: $text-grey-2;
	&:focus {
		outline: none;
	}
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #525f6b transparent transparent transparent;
    pointer-events: none;

  }

  &:before {
    width: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: white;
    content: '';
    pointer-events: none;

  }

  &:hover, &:focus {
    &:before {
      background-color: $white;
    }
  }

  select {
    font-size: 14px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 35px;
    padding-left: 15px;
    border: 1px solid $text-grey-1;
		color: $text-grey-2;

    // Disable default styling on ff
    -moz-appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    // Disable default styling on webkit browsers
    -webkit-appearance: none;

    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }

    &:focus {
			background-color: white;
			border: 1px solid $ui-light-blue-3;
    }
  }
}

// IE 9 only
@media all and (min-width:0\0) and (min-resolution:.001dpcm){
  .select {
    select {
      padding-right: 0;
    }

    &:after, &:before {
      display: none;
    }
  }
}
