@import url('https://fonts.googleapis.com/css?family=Poppins:500,600,700|Raleway:500,500i,600,700,800');

html {
	font-size: 62.5%;
}

body {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
}

h1 {

	font-family: 'Poppins', sans-serif;
	font-size: 6.4rem;
	font-weight: 600;
	line-height: 7.2rem;

	color: $black;

	margin-top: 8.5rem;
	margin-bottom: 3.5rem;

}

h2 {

	font-family: 'Poppins', sans-serif;
	font-size: 4.2rem;
	line-height: 5rem;
	font-weight: 600;

	color: $black;

	margin-top: 7rem;
	margin-bottom: 3rem;

}

h3 {

	font-family: 'Poppins', sans-serif;
	font-size: 3.2rem;
	line-height: 4.8rem;
	font-weight: 600;

	color: $black;

	margin-top: 5.5rem;
	margin-bottom: 2.5rem;

}

.bold {
	font-weight: bolder;
}
.center {
	text-align: center;
}
.line-15 {
	line-height: 1.5;
}

h4 {

	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 600;

	color: $black;

	margin-top: 4.5rem;
	margin-bottom: 2rem;

}

.light-blue-text {
	color: $ui-light-blue-2;
}

.grey-text {
	color: $text-grey-2;
}

.green-text {
	color: $alert-green!important;
}


a {

	color: $ui-light-blue-2;
	text-decoration: none;

	&:hover {
		color: $ui-light-blue-3;
	}

	&:active,
	&.selected {
		color: $ui-dark-blue-1;
	}

}

@media(max-width:1024px) {

	h2 {
		font-size: 3.5rem;
		line-height: 4rem;
	}

}


@media(max-width:800px) {

	h2 {
		font-size: 3rem;
		line-height: 4rem;
	}

}


@media(max-width:500px) {

	h2 {
		font-size: 2.5rem;
		line-height: 3rem;
	}

}
