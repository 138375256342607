.contacts {
	margin-top: 2rem;
	.container-details-race {
		max-width: 75.5rem;
		width: 100%;
		padding: 1.3rem 0rem 1.3rem 0rem;
		.race-infos {
			justify-content: center;
			align-items: center;

			.race-infos-child {
				margin-bottom: 20px;
				width: auto;
				text-align: center;
				.icon-big {
					margin-right: 30px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			.icon {
				margin-right: 7px;
			}
			.text {
				color: $ui-light-blue-2;
			}
		}
	}
}

.tel-box {
	margin-bottom: 6rem;
	.tel {
		font-size: 2.4rem;
	}
	.tel-icon {
		margin-right: 0.8rem;
	}
}
.form-newsletter {
	.form-field {
		width: 100%;
		textarea {
			height: 13rem;
		}
	}
}
@media (min-width: 767px) {
	.contacts {
		margin: 6rem 0;
		.container-details-race {

			.race-infos {
				.race-infos-child {
					padding-top: 0;
					padding-left: 3rem;
					margin-bottom: 0;
					max-width: none;
					min-height: auto;
					margin-bottom: 0;
					padding-right: 40px;
					padding-left: 40px;
					.icon-big {
						margin-right: 50px;
					}
					&:last-child {
						padding-right: 0;
					}
					&:first-child {
						padding-left: 0;
					}
				}
				.title {
					margin-bottom: 4px;
				}
			}
		}
	}
	.form-newsletter {
		.flex-col-start {
			justify-content: space-between;
			flex-direction: row;
			.form-field {
				&:first-child {
					padding-right: 2.5rem
				}
				&:last-child {
					padding-left: 2.5rem
				}
			}
		}
	}
}
