.order-details-specs {

	display: flex;
	justify-content: space-between;

	margin: 40px 0;

	b {
		display: block;
	}

	span {
		color: $text-grey-2;
	}

}

.order-details-subscribers-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.order-set-all-paid-butt {
	margin-bottom: 15px;
}