html,
body {
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;

	margin: 0;

	&.bg-grey {
		background-color: $ui-light-grey;
	}
}

.content-wrapper {
	padding-top: 7rem;
	flex: 1;
	position: relative;
}

.hidden {
	display: none;
}

.search-box,
.select-all {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	input,
	button {
		max-width: 300px;
	}
}

.page-content {
	padding: 2rem;
	&.no-padding {
		padding: 2rem;
	}
}

.page-content--centred {
	max-width: 1200px;
	margin: 0 auto;
}

.tippy-content {
	font-size: 1.4rem;
}

.page-content--white {
	background-color: $white;
}

.page-content--grey {
	background-color: $ui-light-grey;
}

.no-padding {
	padding: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.white-box {

	padding: 2rem;
	background-color: $white;
	box-shadow: $box-shadow;

	@media (max-width: 767px) {
		padding: 1rem;
	}

}

.organizer-link,
.gotoevent-link {

	display:block;
	color: $text-grey-2;
	margin:10px 0;

	a {
		color: #17b2cb;
		text-transform: uppercase;
		font-weight: 800;
	}
}

header.header-flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 7rem auto 3rem auto;

	h2 {
		margin: 0;
	}
}

#form_race_selection.hidden {
	display: none;
}

header.page-header {
	display: flex;
	justify-content: space-between;
	align-items: top;

	margin-top: 7rem;
	margin-bottom: 2rem;

	h2 {
		margin: 0;
	}

	.share-race-results {
		min-width: 20rem;
		text-align: right;

		.share-btn:last-child {
			border: 0;
		}
	}

	.share-btn,
	#print-btn {
		display: inline-block;
		position: relative;

		cursor: pointer;

		width: 5rem;
		padding: 1rem 0;
	}

	.fb-share,
	.twtt-share,
	#print-btn {
		height: 4rem;
		cursor: pointer;
	}
}

.col-50 {
	width: 50%;
	float: left;
}

.col-100 {
	width: 100%;
	float: left;
}
.col-mobile-100 {
	width: 100%;
	float: left;
}
.col-mobile-5 {
	width: 50%;
	float: left;
}
.col-desktop-5 {
	@media (min-width: 992px) {
		width: 50%;
		float: left;
	}
}
.col-big-desktop-3 {
	@media (min-width: 1200px) {
		width: 33.3333%;
		float: left;
	}
}
.center {
	text-align: center;
}
.img-responsive {
	max-width: 100%;
	height: auto;
}
.flex-col-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.flex-center {
	display: flex;
	justify-content: center;
}
.flex-col-end {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.flex-col-start {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.flex-space-between {
	display: flex;
	justify-content: space-between;
}
.flex-space-around {
	display: flex;
	justify-content: space-around;
}
.flex-start {
	display: flex;
	justify-content: flex-start;
}
.flex-end {
	display: flex;
	justify-content: flex-end;
}
.box-center-max-width {
	max-width: $max-width-container;
	margin-left: auto;
	margin-right: auto;
}
.margin-bot-1 {
	margin-bottom: 1rem;
}
.margin-bot-15 {
	margin-bottom: 1.5rem;
}
.margin-top-1 {
	margin-top: 1rem;
}
.margin-top-3 {
	margin-top: 3rem;
}
.box-center {
	margin-left: auto;
	margin-right: auto;
}
.text-container {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}
.text-container.privacy {
	h4 {
		text-align: left;
	}
}
@media (max-width: 767px) {
	.page-content {
		padding: 1rem;
		&.no-padding {
			padding: 0;
		}
	}

	header.page-header {
		margin: 0;
		.share-race-results {
			min-width: 8rem;
			text-align: right;
		}
		.share-btn {
			width: 3rem;
		}
		.race-title {
			word-break: break-word;
		}
	}
}
.full-height {
	height: 100%;
}
.full-width {
	width: 100%;
}

@media print {
	.content-wrapper {
		padding-top: 0rem;
	}

	.page-content {
		padding: 0;
	}
}
