.cookie-container {
	background-color: $ui-dark-blue-2;
	padding: 20px;
	position: fixed;
	bottom: 30px;
	right: 30px;
	max-width: 300px;
	z-index: 2000;
	display: none;
	.more-info {
		cursor: pointer;
	}
	p {
		color: #ffffff;
		font-size: 14px;
		line-height: 1.2;
		margin: 0;
	}
	button {
		padding: 8px 20px;
		min-width: 100px;
	}
	.second-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.check-container {
		display: flex;
		align-items: center;
		.icon {
			margin-right: 5px;
		}
		&:first-child {
			margin-bottom: 10px;
		}
	}
	.show-preferences {
		margin-top: 15px;
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		.icon {
			margin-top: 4px;
		}
		p {
			color: $primary-button-bg;
			margin-right: 8px;
		}
	}
}

@media (min-width: 767px) {
	.cookie-container {
		max-width: 620px;
		padding: 40px 50px;
	}
}

@media (min-width: 767px) {
	.cookie-container button {
		min-width: 150px;
	}
}

.cookie-policy {
	opacity: 0;
	transition: opacity 0.3s ease-out,
		transform 0.4s cubic-bezier(0.68, 0, 0.265, 1) !important;
	p {
		font-size: 14px;
	}
	a {
		color: #edb977;
	}
	&.open {
		opacity: 1;
	}
	.box-content {
		padding: 10px !important;
		.container {
			max-width: 700px !important;
		}
	}
	.title {
		text-align: center;
		margin-bottom: 60px;
		p {
			font-size: 45px;
		}
		.helv {
			margin-bottom: 20px;
		}
	}
	.orange-border {
		width: 150px;
		height: 10px;
		background-color: #edb977;
		margin: auto;
	}
	.table {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		.row {
			display: flex;
			width: 100%;
			border: 1px solid #223440;
			border-top: 0;
			&.head {
				border-top: 1px solid #223440;
				background-color: #edf0f1;
				p {
					font-weight: bold;
				}
			}
		}
		.col {
			width: 50%;
			padding: 5px;
			&:first-child {
				border-right: 1px solid #223440;
			}
		}
	}
}

@media (min-width: 767px) {
	.cookie-policy p {
		font-size: 16px;
	}
}

@media (min-width: 767px) {
	.cookie-policy .box-content {
		padding: 40px !important;
	}
}
