.pagination {

	text-align: center;
	margin: 4rem 0;
	clear: both;

	li {
		display: inline-block;
		font-size: 1.7rem;
	}

}
