.race-block-container-home {
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.race-block-dash-home {
	position: relative;
	background-color: white;
	margin: 7.5px 0;
	box-shadow: 0px 2px 5px 0px rgba(176, 176, 176, 0.5);

	.box-img-organizer {
		display: none;
	}

	.first-box {
		display: flex;
		.box-title {
			text-align: left;
		}
	}

	.second-box {
		display: flex;
		flex-direction: column;
	}

	.delete {
		cursor: pointer;
	}
	.box-title {
		height: 65px;
		background-color: $ui-light-blue-1;
		width: 100%;
	}
	.admin-fun-box {
		position: absolute;
		width: 100%;
		bottom: 0;
		background-color: $ui-light-grey;
		height: 12rem;
		align-items: center;
		padding: 2rem;
		.left-box {
			.icon {
				width: 19px;
				height: 20px;
				margin-right: 12px;
			}
			.line {
				margin-bottom: 7px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.text {
				font-weight: bolder;
				font-size: 1.4rem;
				color: $ui-light-blue-2;
			}
		}
		.right-box {
			padding-left: 1.8rem;
			border-left: 1px solid $ui-light-grey-2;
			.icon {
				margin-bottom: 2.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.container-info-race {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $ui-light-blue-1;

		&.details {
			background-color: white;
			width: 100%;
		}
		.container-info-date-race {
			background-color: $ui-light-blue-1;
			padding: 0 2rem;
			border-left: 1px solid $ui-light-grey-1;

			.box-date-race {
				margin-bottom: 0;
				padding-top: 5px;
				h2 {
					margin: 0;
					font-size: 4rem;
					color: $ui-dark-blue-0;
				}
				.month-race {
					span {
						padding-top: 0;
						font-size: 18px;
					}
				}
			}
		}
	}
	.organizer-box {
		height: 48px;
	}
	.box-info-race {
		margin-top: 5px;
		.box-img-organizer {
			background-color: $white;
			min-width: 117px;
			height: 117px;
			padding-left: 11px;
			.logo-img {
				box-sizing: border-box;
				border: 0.5rem solid #fff;
				background-color: $white;
				width: 95px;
				height: 95px;
				background-size: contain;
			}
		}
		.container-details-race {
			padding-top: 10px;
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
			.race-infos {
				.race-infos__n-subscription {
					display: none;
				}
			}
		}
		.text-race-box {
			padding: 1rem 0;
			height: 120px;
			display: flex;
			flex-direction: column;
		}
		.title {
			justify-content: center;
		}
		.text {
			text-align: center;
		}
	}
	.box-button {
		padding: 1rem;
		align-items: center;
		white-space: nowrap;
	}
}

.race-block-dash-home.admin {
	height: auto;
	position: relative;
	margin-top: 0;
}
.race-block-container-home {
	container-type: inline-size;
	
	@container (min-width: 480px) {
		.second-box {
			flex-direction: row;
			align-items: center;
		}
		.box-title {
			font-size: 2rem;
		}
		.admin-fun-box {
			height: 140px;
			padding: 3rem;
			.left-box {
				.icon {
					margin-right: 16px;
				}
				.line {
					margin-bottom: 13px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.text {
					font-size: 1.6rem;
				}
			}
			.right-box {
				padding-left: 3.5rem;
			}
		}
		.box-info-race {
			margin-top: 0;
			width: 100%;
			.container-details-race {
				padding-top: 0;
			}
			.box-img-organizer {
				min-width: 175px;
				height: 175px;
				padding-left: 24px;
				.logo-img {
					background-size: contain;
					width: 127px;
					height: 127px;
				}
			}
			.container-info-race {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.container-info-date-race {
					.box-date-race {
						padding-top: 5px;
						padding-bottom: 5px;
					}
				}
				.race-infos {
					align-items: center;
					justify-content: flex-start;
					flex-direction: row;
					margin-bottom: 0;
				}
				.race-infos-child {
					padding-bottom: 0;
					margin-bottom: 0;
					margin-right: 30px;
				}
			}
			.text-race-box {
				padding: 2rem 0;
			}
			.text {
				text-align: left;
			}
			.title {
				justify-content: flex-start;
			}
		}
		.box-button {
			padding: 2.3rem 2rem;
			align-items: center;
			button {
				width: 150px;
				&:first-child {
					margin-bottom: 2.3rem;
				}
			}
		}
	}

	@container (min-width: 680px) {

		.admin {
			padding-bottom: 100px;
		}

		.second-box {
			padding-top: 40px;
			.text {
				display: block;
				min-height: 35px; // can contain two lines of text
			}
		}
		.admin-fun-box {
			height: auto;
			padding: 2.5rem 4rem;
			.left-box {
				flex-direction: row;
				justify-content: flex-start;
				.icon {
					margin-right: 10px;
					width: 20px;
					height: 22px;
				}
				.line {
					margin-bottom: 0px;
					margin-right: 18px;

					&:last-child {
						margin-right: 0;
					}
				}
				.text {
					font-size: 1.8rem;
				}
			}
			.right-box {
				flex-direction: row;
				justify-content: flex-start;
				.icon {
					margin-right: 2.4rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-bottom: 0;
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.organizer-box {
			height: 64px;
			font-size: 1.8rem;
		}
		.box-title {
			height: 101px;
		}

		.box-date-absolute {
			position: absolute;
			top: 0;
			right: 0;
			margin-top: 28px;
			margin-right: 41px;
			box-shadow: 0px 1px 5px 0px rgba(176, 176, 176, 0.5);

			.container-info-date-race {
				height: auto;
				padding: 0;
				.box-date-race {
					width: 90px;
					height: 86px;
					padding-top: 0px;
					padding-bottom: 0px;
					background-position: center;
					background-size: contain;
					background-repeat: repeat-x;
					background-position: top center;
					background-image: url("../images/icons-race-details/Calendar1.jpg");
					flex-direction: column-reverse;
					.day-race,
					.month-race {
						width: 100%;
						text-align: center;
					}
					.day-race {
						margin-right: 0;
						font-family: "Poppins", sans-serif;
						font-size: 4rem;
					}
					.month-race {
						color: white;
						position: absolute;
						top: 5px;
					}
					.month-race {
						span {
						}
					}
					.day-race {
						margin-bottom: 5px;
						h2 {
							font-size: 4rem;
							margin-top: 10px;
						}
					}
					.year {
						display: none;
					}
				}
			}
		}
		.box-info-race {
			flex-direction: row;
			justify-content: space-between;
			.box-img-organizer {
				min-width: 202px;
				width: 100%;
				height: 202px;
				padding-left: 27px;
				.logo-img {
					background-size: contain;
					width: 147px;
					height: 147px;
				}
			}
			.container-info-race {
				.race-infos {
					flex-direction: row;

					align-items: center;
					margin-bottom: 0;
					min-width: 170px;

					.race-infos__n-subscription {
						display: flex;
					}
					.race-infos-child {
						min-height: 0;
						padding-top: 0;
						margin-right: 30px;
						.icon {
							min-width: 12px;
						}
					}
				}
			}
			.text-race-box {
				font-size: 1.4rem;
				line-height: 2.2rem;
				min-width: 233px;
				width: 100%;
				padding-top: 0;
			}
		}
		.padding-2-left-right {
			padding: 0 4rem;
		}
		.box-button {
			flex-direction: row-reverse;
			justify-content: flex-start;

			button {
				width: 206px;
				&:first-child {
					margin-bottom: 0;
					margin-left: 4rem;
				}
			}
		}
	}

	@container (min-width: 860px) {

		.admin {
			padding-bottom: 100px;
		}

		.box-info-race {
			padding: 0;

			.box-img-organizer {
				min-width: 365px;
			}
			.container-full-img {
				width: 100%;
			}
			.container-info-race {
				.race-infos {
					justify-content: flex-start;
					.race-infos-child {
						border: none;
						padding-left: 0;
						max-width: none;
					}
				}
				.container-details-race {
					padding-right: 16px;
					padding-left: 28px;
				}
			}
			.text-race-box {
				min-width: 311px;
			}
		}
		.box-img-organizer {
			position: absolute;
			top: 0;
			left: 37px;
			bottom: 0;
			display: flex;
			width: 145px;
			height: 145px;
			margin-bottom: auto;
			margin-top: 28.5px;
			align-items: center;
			flex-direction: row;
			background-color: $ui-light-blue-1;

			.logo-img {
				background-size: contain;
				width: 131px;
				height: 131px;
			}
		}
		.second-box,
		.first-box {
			padding-left: 180px;
		}

		.first-box {
			background-color: $ui-light-blue-1;
		}

		.box-title {
			padding: 0 4rem 0 28px;
		}

		.box-button {
			padding: 0 4rem 0 1rem;
		}
	}
}

.race-block-dash-home.admin {
	height: auto;
}

.race-block-dash-home-768 {
}
.race-block-dash-home.admin {
	height: auto;
	padding-bottom: 170px;
}
.race-block-dash-home-400.admin {
	.box-button {
		padding-top: 3.4rem;
		padding-bottom: 3.4rem;
	}
}

.race-block-dash-home-992 {
}
.race-block-dash-home-1200 {
	.admin-fun-box {
		.left-box {
			.line {
				margin-right: 35px;
			}
			.icon {
				margin-right: 15px;
				width: 24px;
				height: 27px;
			}
			.text {
				font-size: 2rem;
			}
		}
		.right-box {
			.icon {
				margin-right: 3.5rem;
			}
		}
	}
	.box-info-race {
		padding-bottom: 5rem;
		.text-race-box {
			min-width: 450px;
		}
	}
	.box-button {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-bottom: 0px;
		flex-direction: row-reverse;
		justify-content: flex-start;
		padding-top: 3.4rem;
		padding-bottom: 5rem;
		button {
			&:first-child {
				margin-bottom: 0;
				margin-left: 4rem;
			}
		}
	}
}
.race-block-dash-home-1400 {
	.box-button {
		margin-bottom: 0px;
	}
	.container-info-race {
		margin-bottom: 0;
		.container-info-date-race {
			.box-date-race {
				width: 10.1rem;
				height: 9.5rem;
			}
		}
	}
}

.race-block-dash-home-1720 {
	.box-info-race {
		.text-race-box {
			min-width: 690px;
		}
	}
}

.race-block-dash-home-2000 {
	.box-info-race {
		.text-race-box {
			min-width: 990px;
		}
	}
}
.padding-2-left-right {
	padding: 0 2rem;
}
//compact-box
.race-block-dash-home {
	&.compact {
		padding-bottom: 0px;
		.box-flex-between {
			width: 100%;
			flex-direction: column;
		}
		.box-title {
			font-size: 1.5rem;
			height: 60px;
		}
		.container-info-race {
			.container-info-date-race {
				height: 30px;
				.box-date-race {
					.day-race {
						margin-right: 0;
						padding-right: 7px;
						h2 {
							font-size: 1.7rem;
						}
					}
					.month-race {
						padding-right: 7px;
						span {
							font-size: 1.7rem;
						}
					}
					.month-race.year {
						span {
							color: $text-grey-2;
						}
					}
				}
			}
		}
		.box-info-race {
			.container-details-race {
				padding: 0.5rem 0rem;
			}
			.race-infos {
				flex-direction: row;
				justify-content: center;
				width: 100%;
				padding: 1rem 0;
			}
			.race-infos__number {
				padding-right: 15px;
				border-right: 1px solid $ui-dark-grey;
				margin-bottom: 0;
				padding-bottom: 0;
				// width: 100%;
			}
			.race-infos__type {
				padding-left: 15px;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 0;
				.type {
					margin-left: 5px;
					span {
						color: $text-grey-2;
					}
				}
			}
		}
	}
}
.race-block-dash-home-400 {
	&.compact {
		.box-info-race {
			.container-info-race {
				.container-details-race {
					padding: 0rem;
				}
				.race-infos {
					flex-direction: row;
					justify-content: flex-start;
				}
			}
		}
		.box-button {
			padding-top: 1rem;
			padding-bottom: 1rem;

			padding-right: 2px;
			flex-direction: row;
			justify-content: flex-end;
		}
	}
}
.race-block-dash-home-768 {
	&.compact {
		.box-date-absolute {
			box-shadow: none;
			margin-top: 6px;
			width: 180px;
			justify-content: flex-end;
			padding: 10px 0;
			margin-right: 20px;
			.container-info-date-race {
				background-color: transparent;
				.box-date-race {
					width: auto;
					height: auto;
					background: none;
					flex-wrap: inherit;
					justify-content: flex-end;
					.month-race {
						width: auto;
						span {
							line-height: 30px;
							font-size: 18px;
							padding-top: 0;
							color: $link-azure-1;
						}
					}
					.day-race {
						width: auto;
						display: flex;
						flex-direction: column;
						justify-content: center;
						h2 {
							font-size: 24px;
							margin-top: 0;
							line-height: 1;
						}
					}
				}
			}
		}
		.box-info-race {
			margin-top: 0;
		}
		.box-flex-between {
			flex-direction: row;
		}
		.box-button {
			padding-right: 2px;
		}
	}
}
