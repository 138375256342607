.subscribers-list-header {
	header {
		display: flex;
		justify-content: space-between;
	}

	h2 {
		margin-bottom: 0;
	}

	h4 {
		margin-top: 0;
	}
}

.delete-column,
.details-column,
.confirmed-column,
.paid-column,
.certificate-column,
.card-column {
	span {
		display: inline-block;
		width: 3rem;
		height: 3rem;
		background-image: url("../images/icons/icon-undone.svg");
		background-size: contain;
		font-size: 0;
	}
}

.table-actions {
	float: right;
}

.confirmed-column.confirmed span {
	background-image: url("../images/icons/icon-done.svg");
}

.paid-column.paid span {
	background-image: url("../images/icons/icon-done.svg");
}

.certificate-column,
.card-column {
	span.card-icon {
		background-image: url("../images/icons/icon-card.svg");
	}

	span.cert-icon {
		background-image: url("../images/icons/icon-medical-certificate.svg");
	}

	span.card-icon.not_provided,
	span.cert-icon.not_provided {
		opacity: 0.3;
	}

	span.card-icon.only_card_code {
		opacity: 1;
	}
	span.card-icon.to_be_validated {
		background-image: url("../images/icons/icon-card-yellow.svg");
	}
	span.card-icon.valid {
		background-image: url("../images/icons/icon-card-green.svg");
	}
	span.card-icon.invalid {
		background-image: url("../images/icons/icon-card-red.svg");
	}

	span.cert-icon.to_be_validated {
		background-image: url("../images/icons/icon-medical-certificate-yellow.svg");
	}
	span.cert-icon.valid {
		background-image: url("../images/icons/icon-medical-certificate-green.svg");
	}
	span.cert-icon.invalid {
		background-image: url("../images/icons/icon-medical-certificate-red.svg");
	}
}

.race-subscriptions-table
	tr.race-subscription
	span.card-icon:not(.not_provided),
.race-subscriptions-table
	tr.race-subscription
	span.cert-icon:not(.not_provided),
.circuit-subscriptions-table span.card-icon:not(.not_provided),
.circuit-subscriptions-table span.cert-icon:not(.not_provided) {
	cursor: pointer;
}

.details-column {
	span {
		background-image: url("../images/icons/icon-lens.svg");
		cursor: pointer;
	}
}

.delete-column {
	span {
		background-image: url("../images/icons/delete.svg");
		cursor: pointer;
	}
}

.subscriptions-status {
	h4 {
		color: $black;
		margin-bottom: 1rem;
	}

	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	color: $text-grey-3;

	.indented-li {
		padding-left: 20px;
	}

	li.separator {
		margin: 10px 0;
	}
}

.certificates-navigator-wrapper,
.cards-navigator-wrapper {
	display: none;

	position: fixed;
	top: 0;

	background-color: rgba(0, 0, 0, 0.4);
	width: 100vw;
	height: 100vh;
	z-index: 9999;

	align-items: center;
	justify-content: center;

	&.visible {
		display: flex;
	}
}

.certificates-navigator,
.cards-navigator {
	width: 100%;
	max-width: 1000px;
	background-color: $ui-lighter-grey;
	border: 5px solid $ui-yellow;

	&.valid {
		border-color: $alert-green;
	}

	&.invalid {
		border-color: $alert-red;
	}

	.preview__pdf,
	.preview__image {
		width: 100%;
		height: 500px;
	}

	.preview__image {
		overflow: scroll;
	}

	fieldset {
		align-items: center;

		padding: 20px;
		row-gap: 10px;

		.validation-tools {
			display: flex;
			justify-content: space-between;
			padding-bottom: 10px;
		}

		.options {
			align-self: center;
		}

		.options > h4 {
			margin-right: 40px;
		}

		.options > div {
			display: inline-block;
			margin-right: 20px;
		}

		input[type="date"] {
			border: 1px solid $text-grey-1;
			padding: 5px;

			&.error {
				border-color: red;
			}
		}

		.navigation-tools {
			width: 100%;
			padding-top: 10px;
			display: flex;
			justify-content: space-between;

			.confirm-message {
				align-self: center;

				opacity: 0;
				transition: opacity 0.3s;

				&.visible {
					opacity: 1;
				}
			}
		}

		h4 {
			display: inline-block;
			margin: 0;
			margin-right: 20px;
		}

		> div:nth-child(2),
		> div:nth-child(4) {
			justify-self: flex-end;
		}
	}
}

.certificate > h4,
.card > h4 {
	margin: 0;
	padding: 20px;
}
