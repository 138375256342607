.racer-details {

	.col-left {

		width: 30%;
		float: left;
		padding-right: 2rem;
		box-sizing: border-box;

	}

	.col-right {
		width: 70%;
		float: right;
	}

}

.close-details-button {

	font-size: 2rem;
	font-weight: 700;
	line-height: 4rem;

	.back-button {

		cursor: pointer;

		background-image: url('../images/icons/icon-arrow-left.svg');
		background-position: left center;

		padding-left: 1.5rem;

		padding-right: 4rem;
		border-right: 1px solid $ui-dark-grey;

	}

	.route-name {

		padding-left: 4rem;
		color: $ui-dark-blue-1;

	}

	* {
		display: inline-block;
	}


}

.box {

	background-color: $white;
	box-shadow: $box-shadow;
	box-sizing: border-box;

	padding: 2rem;
	margin-bottom: 2rem;

}

.box__box-title {
	background-color: $ui-light-blue-1;
	padding: 1.5rem;
	font-weight: 800;
	color: $ui-dark-blue-1;
	margin-bottom: 1rem;
}



.racer-data {

	padding: 0;
	position: relative;

	text-align: right;

	.avatar {

		height: 32rem;

		background-color: $ui-light-blue-1;
		background-image: url('../images/avatar-placeholder.svg');
		background-position: center bottom;
		background-size: auto 85%;

	}

	.racer-header {
		text-align: center;
	}

	.racer-initials {

		display: block;
		margin: 0 auto;
		margin-top: -4rem;

		background-color: $ui-yellow;

		color: $white;
		font-size: 3rem;
		font-weight: 700;
		line-height: 8rem;
		text-align: center;

		width: 8rem;

		border-radius: 4rem;

	}

	h4 {
		margin: 2.5rem 2rem;
	}

	ul {
		padding: 0 2rem 2rem 2rem;
	}

	li {

		margin-bottom: 2rem;
		color: $text-grey-2;
		padding-left: 3.5rem;

		background-position: center left;
		background-size: auto 100%;

	}

	span, label {
		display: inline-block;
		color: $ui-dark-blue-2;
	}

	label {

		width: 40%;
		float: left;

		text-align: left;

		font-weight: 700;

	}

	span {
		text-align: right;
		width: 60%;
		white-space: nowrap;
		overflow: hidden;
	}

	.racer-category {
		background-image: url("../images/icons/icon-category.svg");
	}

	.racer-gender {
		background-image: url("../images/icons/icon-gender.svg");
	}

	.racer-nationality {
		background-image: url("../images/icons/icon-world.svg");
	}

	.racer-bib {
		background-image: url("../images/icons/icon-bib.svg");
	}

	.racer-team {
		background-image: url("../images/icons/icon-team.svg");
	}

	.racer-chip {
		background-image: url("../images/icons/icon-chip.svg");
	}


}

.stats-container {

	font-family: 'Poppins', sans-serif;

	.big-box,
	.small-box {

		float: left;

		padding: 0 1rem;
		box-sizing: border-box;

		margin-bottom: 2rem;

		height: 15rem;

		label {

			display: block;

			font-size: 2rem;
			line-height: 2rem;
			font-weight: 500;

		}

		big {
			font-size: 4rem;
			line-height: 4rem;
			font-weight: 500;
		}

	}

	.big-box {
		width: 33.3333%;
	}

	.small-box {
		width: 22.2222%;
	}

	> div:first-child {
		padding-left: 0;
	}

	> div:nth-child(3) {
		padding-right: 0;
	}

	> div:nth-child(4) {
		padding-left: 0;
	}

	> div:nth-child(7) {
		padding-right: 0;
	}

	.box-content {

		height: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		color: $white;

		padding: 0.5rem;
		box-sizing: border-box;

	}

	.total-time,
	.overall-position {
		.box-content {
			background-color: $link-azure-1;
		}
	}

	.gap {
		.box-content {
			background-color: $link-azure-2;
		}
	}

	.media,
	.team-position,
	.gender-position,
	.category-position {
		.box-content {

			background-color: $link-azure-3;

			big {
				font-size: 3rem;
				line-height: 3.2rem;
			}

		}
	}
}

.split-times-table, .secondary-stats-table {
	td {
		width: 50%;
	}
}

.secondary-stats {
	padding: 0;

	label {
		font-weight: 700;
		background-image: url("../images/icons/icon-time.svg");
		background-position: center left;
		background-size: auto 100%;
		padding-left: 2rem;
		margin-right: 1rem;
	}
}


@media(max-width: 1400px) {

	.stats-container {

		.big-box,
		.small-box {

			label {
				display: block;

				font-size: 1.8rem;
				line-height: 2.2rem;
			}

			big {
				font-size: 2.8rem;
				line-height: 3rem;
			}

		}

		.media,
		.team-position,
		.gender-position,
		.category-position {

			.box-content {

				big {
					font-size: 2.8rem;
					line-height: 2.8rem;
				}

			}

		}


	}

}

@media(max-width: 1024px) {

	.racer-details {

		.col-right {
			width: 60%;
		}

		.col-left {
			width: 40%;
		}

	}

	.stats-container {

		.big-box {
			width: 50%;
		}

		.small-box {
			width: 33.333333%;
		}

		div:nth-child(2) {
			padding-right: 0;
		}

		div:nth-child(3) {
			padding-left: 0;
			padding-right: 1rem;
		}

		div:nth-child(4) {
			padding-left: 1rem;
			padding-right: 0;
		}

		div:nth-child(5) {
			padding-left: 0;
		}

		div:nth-child(7) {
			padding-right: 0;
		}

	}

}


@media(max-width: 800px) {

	.close-details-button {

		font-size: 1.5rem;
		line-height: 2rem;

		.back-button {
			padding-right: 1rem;
		}

		.route-name {
			padding-left: 1rem;
			display: inline;
		}

	}

	.racer-details {

		.col-right {
			width: 50%;
		}

		.col-left {
			width: 50%;
		}

	}

	.stats-container {

		div.big-box,
		div.small-box {

			width: 100%;
			padding: 0;
			height: 10rem;

		}

	}

}

@media(max-width: 600px) {

	.racer-details {

		.col-right,
		.col-left {
			float: none;
			width: auto;
			padding: 0;
		}

		.col-left {
			margin-bottom: 2rem;
		}

	}

}


@media print {

	.main-nav {
		position: relative;
	}

	.footer__content {
		display: none;
	}

	footer.main-footer {
		padding: 0;
	}

	.footer__bottom {
		width: 100%;
	}

	.stats-container div.big-box, .stats-container div.small-box {
		height: 7rem;
	}

	.secondary-stats {
		clear: both;
	}

	.share-race-results, .close-details-button {
		display: none;
	}

	header.page-header {
		padding: 1rem 0;
	}

	.race-results-header header {
		margin: 0;
	}

	[class^="btn"] {
		display: none;
	}

	.print-action-container {
		display: none;
	}

}
