.order-block {

	box-shadow: 0 2px 5px 0 hsla(0,0%,69%,.5);

	header {
		background-color: $ui-light-blue-1;
		padding: 20px;

		.icon-details {
			float: right;
		}
	}

	h3, h4 {
		margin:0;
	}

	h4 {
		color: $link-azure-1;
	}

	.order-subscribers-list, .order-specs, .order-price {
		margin: 10px 20px;
		// background-color: $ui-light-grey;
	}

	.order-actions {
		padding: 20px;
	}

}

.orders-list-container {
	max-width: 80rem;
	margin: 0 auto;

	.order-block {
		margin-bottom: 5rem;
	}
}
