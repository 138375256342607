.section-title {
	width: 100%;
	margin-top: 2rem;
	padding: 2.5rem 0 2.5rem 2rem;
	background-color: $ui-light-blue-1;
	font-size: 1.8rem;
	color: $ui-dark-blue-2;
	font-weight: bolder;
}
.logo-id-chronos-black-print {
	display: none;
}

.print-icon {
	cursor: pointer;
}

.subscription-recap-container {
	max-width: 80rem;
	margin: 0 auto;
	.subscription-recap-box {
		margin-bottom: 7.3rem;
		h3 {
			display: flex;
			justify-content: space-between;
		}
		.container-info-race {
			justify-content: flex-start;
			margin-bottom: 0;
			.race-infos-child {
				max-width: 140px;
			}
			.container-info-date-race {
				padding: 0;
				.box-date-race {
					padding-left: 2rem;
					width: 100%;
					height: 7.2rem;
					margin-bottom: 0;
					.month-race {
						font-size: 2rem;
						span {
							padding-top: 9px;
						}
					}
					.day-race {
						font-size: 4rem;
					}
				}
			}
			.container-details-race {
				padding: 1.3rem 0rem 1.3rem 2rem;
				width: 100%;
			}
		}
		.subscription-username {
			padding: 2rem;
			h3 {
				margin-top: 0;
				margin-bottom: 2rem;
			}
		}
		.subscription-user-info-box {
			margin-bottom: 4.4rem;
			padding-left: 2rem;
			padding-right: 2rem;
			width: 100%;
			.img-user {
				display: none;
				width: 290px;
				background-color: $ui-light-blue-1;
				padding-top: 10px;
				margin-right: 7rem;
				height: 19.3rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.info-user {
				width: 100%;
				.lines-wrapper {
					.line {
						margin-bottom: 2.8rem;
						.file-icon {
							margin-top: 4px;
							margin-right: 0.8rem;
						}
					}
					&:nth-child(2) {
						margin-bottom: 4rem;
					}
				}
			}
		}
	}
}
.file-flex {
	flex-wrap: wrap;
}
.file-icon {
	img {
		width: 17px;
		height: 17px;
	}
}
.price-container {
	width: 100%;
	padding: 0 2rem 0 2rem;
	.price-box {
		max-width: 40rem;
		margin-right: auto;
		.line-price {
			font-size: 1.4rem;
			color: $text-grey-2;
			line-height: 1;
			margin-bottom: 0.7rem;
			span {
				white-space: nowrap;
			}
			.type-price {
				padding-right: 1rem;
			}
			.num-price {
				padding-left: 1rem;
			}
			.separator-box {
				width: 100%;
				.price-separator {
					width: 100%;
					height: 1px;
					background-color: $text-grey-2;
					margin-bottom: 4px;
				}
			}
		}
		.line-price.total {
			margin-top: 2.5rem;
			font-weight: bolder;
			font-size: 1.8rem;
			.separator-box {
				.price-separator {
					height: 2px;
				}
			}
		}
	}
}

.discount-code-price-detail {
	text-transform: uppercase;
}

.payment-method-container {
	width: 100%;
	padding-top: 15px;
	.payment-type-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $ui-light-blue-3;
		font-weight: bolder;
		font-size: 1.2rem;
		.box-input-icon {
			display: flex;
			justify-content: center;
			margin-bottom: 1rem;
			label {
				margin-right: 1rem;
				cursor: pointer;
			}
		}
	}
}
.bank-info-container {
	.container-info-race {
		display: flex;
		justify-content: center;
		flex-direction: row;
		max-width: 50rem;
		padding: 0 2rem;
		margin-left: auto;
		margin-right: auto;
		.icon-bank {
			width: 8rem;
		}
		.container-details-race {
			padding: 0;
			margin: 0;
			max-width: none;
			.race-infos {
				margin-bottom: 0;
				padding: 0;
				.race-infos-child {
					margin-bottom: 1rem;

					&:last-child {
						margin-bottom: 0rem;
					}
					max-width: none;
					width: 100%;
					padding-left: 3rem;
					.title {
						margin-bottom: 0.2rem;
					}
				}
			}
		}

	}
}

@media (min-width: 767px) {
	.bank-info-container {
		.container-info-race {
			.icon-bank {
				width: auto;
			}
			justify-content: flex-start;
			max-width: $max-width-container;
			margin-left: auto;
			margin-right: auto;
			.container-details-race {
				width: 100%;
				.race-infos {
					.race-infos-child {
						width: 50%;
						// padding-left: 7rem;
						padding-top: 0;
						&:first-child {
							margin-bottom: 0;
						}
						&:nth-child(2) {
							// padding-left: 9rem;
						}
					}
				}
			}
		}
	}
	.alert-container {
		max-width: 80rem;
		margin: 0 auto;
		justify-content: flex-start;
		.alert-box {
			padding: 2rem 4rem;
			flex-direction: row;
			justify-content: flex-start;
			text-align: left;
			.icon-alert {
				min-width: 6rem;
				margin-right: 3.5rem;
				margin-bottom: 0;
				display: flex;
				align-items: center;
			}
			h4 {
				margin-bottom: 0;
			}
		}
	}
	.file-icon {
		img {
			width: 27px;
			height: 27px;
		}
	}
	.section-title {
		padding: 2.5rem 0 2.5rem 3rem;
		margin-top: 4rem;
		margin-bottom: 7rem;
	}
	.subscription-recap-container {
		.subscription-recap-box {
			padding-left: 2rem;
			padding-right: 2rem;
			.container-info-race {
				.container-info-date-race {
					.box-date-race {
						padding-left: 0;
						width: 10.2rem;
						height: 10.2rem;
					}
				}
				.race-infos-child {
					max-width: 230px;
				}
				.container-details-race {
					padding: 1.3rem 0rem 1.3rem 7rem;
				}
			}
			.subscription-user-info-box {
				padding-left: 0;
				padding-right: 0;
				.img-user {
					display: block;
				}
			}
			.subscription-username {
				padding: 0;
				h3 {
					margin-bottom: 3rem;
				}
			}
		}
	}

	.price-container {
		.price-box {
			margin-left: auto;
		}
	}

	.payment-method-container {

		.method-payment-box {

			.payment-type-box {

				flex-direction: row;
				justify-content: flex-start;

				.box-input-icon {
					display: flex;
					justify-content: flex-start;
					margin-bottom: 0;
					label {
						margin-right: 2.3rem;
					}
					.icon-method {
						margin-right: 1rem;
					}
				}

				label {
					margin-right: 2.3rem;
				}

			}
		}
	}
}



.apply-discount-form {

	margin: 10rem 0;

	form {
		text-align: center;
	}

	label {
		align-self: center;
		font-weight: bold;
	}

	input[type="text"] {
		margin: 0 2rem;
		width: auto;
	}

}

@media print {

	.subscription-recap-container {

		max-width: 100%;

		.logo-id-chronos-black-print {
			display: block;
			text-align: center;
		}

		.subscription-recap-box {
			margin-bottom: 1rem;
		}

	}

}
