.box-tickets {
	width: 100%;

	max-width: 1000px;
	margin: 5rem auto;

	.box-circuit {
		border: 3px solid $ui-light-blue-2;
		padding-bottom: 20px;
		margin-bottom: 30px;

		h3 {
			margin-top: 2rem;
		}
	}

	.ticket {
		box-shadow: $box-shadow;

		&:last-child {
			margin-bottom: 0;
		}

		&.selectable {
			border: 3px solid $white;
			cursor: pointer;

			&:hover {
				border: 3px solid $ui-light-blue-2;
			}

			&:active,
			&.selected {
				border: 3px solid $ui-dark-blue-3;
			}
		}

		margin-bottom: 1.4rem;
		padding: 1.4rem 1.6rem 2rem 1.6rem;
		align-items: center;
		background-color: white;
		.box-ticket {
			display: flex;
			width: 100%;
			padding: 0.8rem 1.5rem 1.5rem 1.5rem;
			flex-direction: column;
			justify-content: center;
			&__price {
				@extend .box-ticket;
				background-color: $ui-lighter-grey;
				.bell-icon {
					display: inline;
					top: 0;
					left: 0;
					img {
						width: 9px;
						height: 10px;
					}
				}
				.inscription-end {
					position: relative;
					.text {
						max-width: 130px;
						display: inline;
						font-size: 1.2rem;
						font-weight: 500;
						color: $text-grey-2;
						span {
							display: inline;
						}
					}
				}
				.price {
					.money-icon {
						margin-right: 0.7rem;
						img {
							width: 17px;
							height: 14px;
						}
					}
					.text {
						font-size: 1.8rem;
						font-weight: bolder;
						color: $text-grey-2;
					}
				}
			}
			&__info {
				@extend .box-ticket;
				.info-box {
					margin-bottom: 1rem;
					.icon {
						margin-right: 0.5rem;
					}
					.info-title {
						.text {
							font-size: 1.2rem;
							color: $text-blue-1;
							font-weight: bolder;
						}
					}

					.text {
						line-height: 1.5rem;
						font-size: 1.2rem;
						color: $text-grey-2;
						font-weight: 500;
					}
					.second-title {
						@extend .text;
						font-weight: bolder;
					}
				}
			}
		}
		.title {
			font-size: 1.8rem;
			font-weight: bolder;
			color: $text-blue-2;
			line-height: 2.8rem;
		}
		.button-box {
			text-align: center;
			margin-top: 1.5rem;
		}
		.btn-default {
			font-size: 1rem;
			padding: 1rem 5rem;
		}
	}
}
@media (min-width: 992px) {
	.box-tickets {
		.ticket {
			.button-box {
				text-align: center;
				margin-top: 0;
			}
			.title {
				margin-bottom: 0;
			}
			.box-ticket {
				flex-direction: row;
				justify-content: space-between;
				&__price {
					padding: 0.8rem 1.5rem;
					.inscription-end {
						align-items: center;
						.text {
							max-width: none;
						}
					}
					.price {
						margin-left: 2rem;
						justify-content: center;
					}
				}
				&__info {
					margin-top: 2.5rem;
					justify-content: space-between;
					.info-box-container {
						justify-content: flex-start;
						flex-direction: row;
					}
					.info-box {
						justify-content: flex-start;
						flex-direction: row;
						margin-right: 5.6rem;
						.info-title {
							margin-right: 1.3rem;
						}
						.second-title {
							margin-right: 1.3rem;
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
						}
					}
				}
			}
		}
	}
}
