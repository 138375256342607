.race-event-link {
	display: block;
	margin: 10px 0;
}

.organizer-header {
	background-color: $text-grey-1;
	padding: 2rem;
	background-size: cover;

	margin: 5rem 0;

	.organizer-logo {
		background-color: $ui-light-grey;
		background-size: contain;
		background-position: center center;

		display: block;

		height: 30rem;
		width: 30rem;

		border: 0.5rem solid $white;
	}

}

.wrapper-create-category-options {
	display: none;
}

.race-description-text {
	margin-top: 1rem;
	font-size: 1.4rem;
	color: $text-grey-2;
	text-align: left;

	p {
		margin: 0;
	}
}

.race-subscribe-steps-container {
	.box {
		height: 100%;
	}

	.col {
		width: 33.33%;
		height: 100%;
		box-sizing: border-box;
		float: left;

		&:nth-child(1) {
			padding-right: 2rem;
		}

		&:nth-child(3) {
			padding-left: 2rem;
		}
	}
}

.race-infos {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	color: $text-grey-3;

	span {
		display: inline-block;
		text-align: left;

		&.separator {
			display: none;
			padding: 0;
			border-right: 1px solid $text-grey-1;
			height: 100%;
		}
	}

	.icon {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.margin-bottom {
		margin-bottom: 0.8rem;
	}
	&__date {
		.icon {
			margin-right: 0.5rem;
			img {
				width: 12px;
				height: 13px;
			}
		}
	}
	&__type {
		.icon {
			margin-right: 0.5rem;
			img {
				width: 12px;
				height: 11px;
			}
		}
	}
	&__location {
		.icon {
			margin-right: 0.7rem;
			img {
				width: 9px;
				height: 12.12px;
			}
		}
	}
	&__n-subscription {
		.icon {
			margin-right: 0.5rem;
			img {
				width: 11.28px;
				height: 11.75px;
			}
		}
	}
	&__number {
		.title {
			span {
				font-size: 30px;
				font-weight: 500;
				color: $link-azure-1;
			}
		}
	}
}
.title {
	margin-bottom: 3px;
	display: flex;
	justify-content: flex-start;
	span {
		display: block;
		font-weight: 700;
		color: $ui-dark-blue-1;
		font-size: 1.2rem;
		line-height: 1;
	}
}
.text {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	color: $text-grey-2;
	font-size: 1.2rem;
	word-wrap: break-word;
}
.display_race_location_map {
	height: 100%;
}

.verify-email-box {

	padding-bottom: 55px;
	position: relative;

	max-width: 500px;
	margin: 0 auto;
	margin-top: 70px;

	.success-message,
	.error-message,
	.not-confirmed-message {
		display: none;
		position: absolute;
		padding-top: 10px;
		padding-right: 20px;
	}

	.success-message {
		color: $alert-green;
	}

	.error-message {
		color: $alert-red;
	}

	.not-confirmed-message {
		color: $alert-yellow;
	}

}

.race-page-contacts {
	padding-bottom: 3rem;
	color: $text-grey-2;

	h2 {
		margin: 2rem;
	}

	h3 {
		margin: 0;
	}

	.race-manager-contacts {
		margin-top: 3rem;
	}

	ul {
		margin-bottom: 2rem;
		text-align: center;
	}

	li {
		padding: 0.3rem 0;
	}

	.text {
		padding: 0 1.5rem;
	}

	.button-box {
		margin-top: 3rem;
	}
}

.subscription-tax-info-box {
	button {
		float: right;
	}
}
.container-info-race {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.container-info-date-race {
		padding: 1.2rem 2rem;
		background-color: $ui-lighter-grey;
		.box-date-race {
			margin-bottom: 1.7rem;
			display: flex;
			justify-content: flex-start;
			.day-race {
				margin-right: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				color: $text-blue-1;
				font-size: 2.4rem;
				line-height: 1;
			}
			.month-race {
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 1.8rem;
				line-height: 1;
				font-family: "Raleway", sans-serif;
				font-weight: 700;
				color: $link-azure-1;
				span {
					padding-top: 5px;
				}
			}
		}
		.box-enroll-date-race {
			@extend .box-date-race;
			margin-bottom: 0;
			.box-info-enroll-race {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.title {
					font-family: "Raleway", sans-serif;
					font-weight: 700;
					color: $ui-dark-blue-1;
					font-size: 1.4rem;
				}
				.time {
					@extend .title;
					font-weight: 500;
					color: $text-grey-2;
					font-size: 1.2rem;
				}
			}
			.bell-icon {
				margin-right: 1.5rem;
				display: flex;
				justify-content: center;
				align-content: flex-start;

				img {
					width: 17px;
					height: 22px;
				}
			}
		}
	}
	.description-box {
		margin-bottom: 23px;
		div {
			transition: 0.38s max-height ease-out;
			overflow: hidden;
		}
		div.collapse {
			transition: 0.38s max-height ease-out;
			max-height: 50px;
		}
		.read-more {
			margin-top: 15px;
			cursor: pointer;
			font-family: "Raleway", sans-serif;
			font-size: 1rem;
			font-weight: bold;
			color: $ui-light-blue-2;
		}
	}
	.subscription-info-box {
		padding: 1.3rem 2rem 2.5rem 2rem;
		.box-download-info {
			margin-bottom: 10px;
		}
		span {
			line-height: 1;
		}
		.icon-download {
			margin-right: 10px;
			img {
				width: 13px;
				height: 12px;
			}
		}
		.text-download {
			font-size: 1.2rem;
			font-weight: bolder;
			color: $ui-light-blue-2;
			font-family: "Raleway", sans-serif;
		}
		.subscription-button {
			margin-top: 30px;
			max-width: 400px;
		}
	}
	.container-details-race {
		padding: 1.3rem 2rem;
	}
}
@media (max-width: 1400px) {
	.organizer-header {
		.organizer-logo {
			height: 20rem;
			width: 20rem;
		}
	}
}
@media (max-width: 767px) {
	.organizer-header {
		margin: 0;
		.organizer-logo {
			height: 11rem;
			width: 11rem;
		}
	}
}
@media (min-width: 767px) {
	.title span,
	.text {
		font-size: 1.4rem;
	}
	.title {
		margin-bottom: 8px;
	}
	.race-infos {
		display: flex;
		justify-content: center;
		align-items: center;
		height: auto;

		margin: 0 0 4rem;

		color: $text-grey-3;

		big {
			display: block;
			font-weight: 700;
			color: $ui-dark-blue-1;
			margin-bottom: 1rem;
		}

		span {
			display: inline-block;
			text-align: left;

			&.separator {
				padding: 0;
				border-right: 1px solid $text-grey-1;
				height: 100%;
			}
		}
	}

	.container-info-race.race-page {
		flex-direction: row;
		justify-content: center;
		.container-info-date-race {
			background-color: white;
			padding-left: 1.5rem;
			padding-right: 0;
			.box-date-race {
				width: 10.1rem;
				height: 9.5rem;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("../images/icons-race-details/Calendar1.jpg");
				flex-wrap: wrap-reverse;
				.day-race,
				.month-race {
					width: 100%;
					text-align: center;
					justify-content: flex-start;
				}
				.day-race {
					margin-right: 0;
					font-family: "Poppins", sans-serif;
					font-size: 4rem;
				}
				.month-race {
					color: white;
				}
			}
			.box-date-race.closed {
				background-image: url("../images/icons-race-details/Calendar2.jpg");
			}
			.box-enroll-date-race {
				background: none;
				flex-wrap: nowrap;
				height: auto;
			}
		}
		.race-infos {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.race-infos-child {
			padding-top: 10px;

			min-height: 81px;
			width: 100%;
			max-width: 100px;
			padding-right: 12px;
		}
		.subscription-info-box {
			flex-direction: column-reverse;
			justify-content: flex-end;
			.text-download {
				font-size: 1.6rem;
			}
			.icon-download {
				img {
					width: 2.6rem;
					height: 2.4rem;
				}
			}
			.subscription-button {
				margin-top: 0;
				margin-bottom: 56px;
			}
		}
	}
}
@media (min-width: 992px) {
	.container-info-race.race-page {
		.container-details-race {
			max-width: 80rem;
			padding: 1.3rem 3rem 1.3rem 4rem;
		}
		.race-infos-child {
			border-right: 1px solid $text-grey-2;
			max-width: 229px;
			padding-right: 30px;
			padding-left: 2.5rem;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				border: none;
			}
		}
	}
}
@media (min-width: 1400px) {
	.container-info-race.race-page {
		margin-bottom: 10rem;
		.container-details-race {
			max-width: 91.5rem;
			padding: 1.3rem 10rem 1.3rem 12rem;
		}
		.container-info-date-race {
			.box-date-race {
				width: 17.1rem;
				height: 16.5rem;

				.day-race {
					padding-top: 10px;
					font-size: 6rem;
				}
				.month-race {
					font-size: 3rem;
					span {
						padding-top: 15px;
					}
				}
			}
			.box-enroll-date-race {
				height: auto;
			}
		}
		.description-box {
			.read-more {
				font-size: 1.6rem;
			}
		}
		.subscription-button {
			.btn-primary {
				font-size: 2rem;
				min-width: 30rem;
			}
		}
	}
	.organizer-header {
		margin-bottom: 10rem;
	}
}
