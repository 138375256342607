table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	text-decoration: none;
}

* {
	background-repeat: no-repeat;
	background-position: center center;
  box-sizing: border-box;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}
