.container-news {
  width: 100%;
  margin-top: 30px;
  float: left;
  margin-bottom: 60px;
}

.mar-top-30 {
  margin-top: 30px;
}
#news {
  h3 {
    margin-top: 0;
  }
}
.news-box-slim {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px 0px rgba(176, 176, 176, 0.5);
  margin-bottom: 30px;
  max-width: 429px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    float: left;
    width: 48%;
    margin-right: 2%;

    &:nth-child(2) {
      margin-right: 0;
      margin-left: 2%;
    }

    // &:last-child {
    //   display: none;
    // }
  }

  @media (min-width: 991px) {
    width: 30.5%;
    margin-left: 2%;
    margin-right: 2%;

    &:last-child {
      display: flex;
      margin-right: 0;
    }

    &:first-child {
      display: flex;
      margin-left: 0;
    }

    &:nth-child(2) {
      margin-right: 2%;
      margin-left: 2%;
    }
  }

  .img-news {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: red;
    min-height: 137px;
    width: 100%;

    @media (min-width: 400px) {
      min-height: 191px;
    
    }
  }

  .info-race-box-news {
    width: 100%;
    min-height: 55px;
    display: flex;
    justify-content: space-between;
    background-color: $ui-lighter-grey;
    align-items: center;

    .date {
      display: flex;
      align-items: center;

      .day-race {

        span {
          line-height: 1;
          font-size: 31px;
          color: $ui-dark-blue-1;
          font-weight: 600;
        }
        margin-right: 10px;
      }

      .month-race {
        span {
          font-size: 18px;
          color: $alert-azure;
          font-weight: 900;
          
        }
      }
    }

    .where-type {
      display: flex;
      flex-direction: row;
      align-items: center;

      .where {
        padding-right: 20px;
        border-right: 1px solid $ui-dark-grey;
      }
      .icon-type {
        margin-left: 20px;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .icon {
          display: flex;
          align-items: center;
          margin-right: 6px;
        }
      }
      .text-where {
        span {
          font-weight: 600;
          color: $text-grey-2;
        }
      }
    }
  }

  .padding-news-slim {
    padding-left: 18px;
    padding-right: 18px;

    @media (min-width: 400px) {
      padding-left: 25px;
      padding-right: 25px;
    
    }
  }

  .box-content-news {
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 34px;


    .title-news {
      margin-bottom: 20px;
      span {
        font-size: 20px;
        font-weight: 900;
        color: $ui-dark-blue-2;
      }
    }

    .text-news {
      

      span {
        font-weight: 600;
        color: $text-grey-2;
      }
    }

    a {
      span {
        color: $alert-azure;
        font-weight: 900;
      }
    }
  }
}

#homepage {

  #news {
    .container.news {
      max-width: 1300px
    }
  }
}