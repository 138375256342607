input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
	padding: 1.5rem;
	outline: none;
	border: 1px solid $text-grey-1;

	box-sizing: border-box;

	color: $text-grey-2;

	&:focus {
		background-color: $ui-light-grey;
		border: 1px solid $ui-light-blue-3;
	}

	&.search {

		padding-left: 45px;

		background-image: url("../images/icons/icon-lens.svg");
		background-position: 10px center;
		background-repeat: no-repeat;

		font-size: 1.8rem;

	}

	@media (max-width: 767px) {
		padding: 0.9rem;
	}

}
