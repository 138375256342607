/* Dashboard */
.race-actions-tools {
	a, div {
		display: inline-block;
		margin: 0 5px;
	}

	img {
		width: 25px;
	}
}

.location-map {
	width: 100%;
	height: 400px;
	margin-top: 2rem;
}

.search-location {
	&:after {
		clear: both;
		content: " ";
		display: block;
		height: 0;
		visibility: hidden;
	}

	#search_address {
		float: left;
		width: 80%;
	}

	#search-location-button {
		width: 19%;
		float: right;
		height: 47px;
	}
}

.location-picker-wrapper:after {
	clear: both;
	content: " ";
	display: block;
	height: 0;
	visibility: hidden;
}

.create-race-nav,
.create-circuit-nav {
	margin: 7rem 0;

	a {
		padding: 0 10px;
	}

	.selected {
		font-weight: 800;
	}
}

.race_organizer_fields {
	display: none;

	&.open {
		display: block;
	}
}

.chips-bibs-range {
	display: none;

	&.open {
		display: block;
	}
}

#race_description {
	display: none;
}

#editor.ql-container {
	height: 300px;
}

// STEP 5
.categories-archive-wrapper {
	display: none;
}
